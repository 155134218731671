import React, { useEffect, useState } from "react";

const Timer = ({ onLogout }) => {
  const timerDuration = 45 * 60; // 45 minutes in seconds
  const idleDuration = 30 * 60; // 10 minutes in seconds
  const [secondsRemaining, setSecondsRemaining] = useState(
    timerDuration - getElapsedTime()
  );

  useEffect(() => {
    let timer;

    const handleTimerTick = () => {
      if (!document.hidden && hasMouseMoved()) {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      } else {
        setSecondsRemaining(idleDuration); // Reset the timer to 20 minutes if no mouse movement
      }
    };

    timer = setInterval(handleTimerTick, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (secondsRemaining <= 0) {
      onLogout();
    }
  }, [secondsRemaining, onLogout]);

  useEffect(() => {
    const timerStartTime = localStorage.getItem("timerStartTime");
    const currentTime = Date.now();
    const elapsedTime = Math.floor(
      (currentTime - parseInt(timerStartTime)) / 1000
    );
    const remainingTime = timerDuration - elapsedTime;

    if (timerStartTime && elapsedTime < timerDuration) {
      setSecondsRemaining(remainingTime);
    } else {
      localStorage.setItem("timerStartTime", currentTime.toString());
    }
  }, []);

  function getElapsedTime() {
    const timerStartTime = localStorage.getItem("timerStartTime");
    if (timerStartTime) {
      const currentTime = Date.now();
      const elapsedTime = Math.floor(
        (currentTime - parseInt(timerStartTime)) / 1000
      );
      return Math.min(elapsedTime, timerDuration);
    }
    return 0;
  }

  function hasMouseMoved() {
    // You may need to adjust this threshold based on your application's sensitivity
    const mouseThreshold = 5;

    // Check if the mouse coordinates have changed
    return (
      Math.abs(lastMouseX - mouseX) > mouseThreshold ||
      Math.abs(lastMouseY - mouseY) > mouseThreshold
    );
  }

  let mouseX = 0;
  let mouseY = 0;
  let lastMouseX = 0;
  let lastMouseY = 0;

  useEffect(() => {
    const handleMouseMove = (e) => {
      lastMouseX = mouseX;
      lastMouseY = mouseY;
      mouseX = e.pageX;
      mouseY = e.pageY;
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return null;
};

export default Timer;
