import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import Login from "./user-pages/Login";
import { useSelector } from "react-redux";
import NoAccess from "./no-access/no-access";

// Lazy-loaded components
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const SearchList = lazy(() => import("./utils/search_list/search-list"));
const VerifyCashOrders = lazy(() =>
  import("./Support/verify_cash_orders/verify_one/paginate")
);
const VerifyAll = lazy(() =>
  import("./Support/verify_cash_orders/verify_all/verify_all")
);
const AttendeesSupport = lazy(() =>
  import("./Support/attendees_support/attendees_support")
);

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));
const WebBook = lazy(() => import("./control/website-book/website-book"));
const NewOrg = lazy(() => import("./control/new_org/verify_org"));
const Refunds = lazy(() => import("./control/refunds/refunds"));

const FinanceEventsByDate = lazy(() =>
  import("./finance/event/events_by_date")
);
const FinanceEventsByName = lazy(() =>
  import("./finance/event/events_by_name")
);

const BasicElements = lazy(() => import("./form-elements/BasicElements"));
const BasicTable = lazy(() => import("./tables/BasicTable"));
const Mdi = lazy(() => import("./icons/Mdi"));
const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));
const UnderDevelopment = lazy(() => import("./error-pages/under_development"));

const routes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/search-list", component: SearchList },
  { path: "/support/verify-cash-orders", component: VerifyCashOrders },
  { path: "/support/verify-All", component: VerifyAll },
  { path: "/support/attendees-support", component: AttendeesSupport },
  { path: "/basic-ui/buttons", component: Buttons },
  { path: "/basic-ui/dropdowns", component: Dropdowns },
  { path: "/basic-ui/typography", component: Typography },
  { path: "/control/website-book", component: WebBook },
  { path: "/control/new-orgs", component: NewOrg },
  { path: "/support/refunds", component: Refunds },
  { path: "/finance/events-by-name", component: FinanceEventsByName },
  { path: "/finance/events-by-date", component: FinanceEventsByDate },
  { path: "/form-elements/basic-elements", component: BasicElements },
  { path: "/tables/basic-table", component: BasicTable },
  { path: "/icons/mdi", component: Mdi },
  { path: "/charts/chart-js", component: ChartJs },
  { path: "/error500", component: Error500 },
  { path: "/error404", component: Error404 },
  { path: "/under-development", component: UnderDevelopment },
];

export default function AppRoutes() {
  const userInfo = useSelector((state) => state.auth);

  const getAccessibleRoutes = (role, pages) => {
    if (role === "admin") {
      return routes;
    }
    if (!pages) {
      return [];
    }
    return routes.filter((route) => pages.includes(route.path));
  };
  const accessibleRoutes = userInfo?.user
    ? getAccessibleRoutes(userInfo.user.role, userInfo.user.pages)
    : [];
  console.log(accessibleRoutes);
  const defaultPath =
    userInfo?.user?.role !== "admin" && accessibleRoutes.length > 0
      ? accessibleRoutes[0].path
      : "/support/verify-cash-orders";

  return (
    <Suspense fallback={<Spinner />}>
      {userInfo?.user ? (
        <Switch>
          {accessibleRoutes.length > 0 ? (
            accessibleRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
              />
            ))
          ) : (
            <Route path="/no-access" component={NoAccess} />
          )}
          <Redirect
            to={accessibleRoutes.length > 0 ? defaultPath : "/no-access"}
          />
          <Route path="*" component={NoAccess} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact render={(props) => <Login />} />
          <Redirect to="/" />
        </Switch>
      )}
    </Suspense>
  );
}
