import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../slices/authSlice";
import { login } from "../../Network/ApiAxios";
import { Popups } from "../utils/popups/popups";
import LoaderSignal from "../utils/loader/loader";
export default function Login(props) {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [resultMsg, setResultMsg] = useState("");
  const [resultDone, setResultDone] = useState(false);
  const handleLogin = async (event) => {
    // authenticate user
    // localStorage.clear();
    event.preventDefault();

    // dispatch login action
    let form = document.forms.loginForm.elements;
    let fullForm = {};
    for (let i = 0; i < form.length; i++) {
      let key = form[i].name;
      let value = form[i].value;
      fullForm[key] = value;
      // console.log(`s${form.name}s`);
    }

    const email = fullForm.email;
    const password = fullForm.password;

    try {
      setLoading(true);
      setResultDone(false);
      localStorage.removeItem("idToken");
      const response = await login(email, password);
      if (response.data.status === "Fail") {
        // toast(response.data.message, { type: "error" });
        // // console.log("err1");
        setResultMsg(`${response.data.message}`);
        setResultDone(true);
        // console.log(response);
        // // console.log(response);
        setLoading(false);
      } else {
        // console.log(response["data"]["idToken"]);
        // localStorage.removeItem("idToken");
        localStorage.setItem("idToken", response["data"]["idToken"]);
        const currentUser = response.data;

        dispatch(setUser({ ...currentUser }));

        // do your login logic here
        // props.loginHandler();
        // window.location.reload();
        // console.log(userInfo);
      }
    } catch (error) {
      // toast(error);
      // console.log(error, "err21");
      setResultDone(true);
      setResultMsg(error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <div className="new-login">
      <section>
        {" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />{" "}
        <span /> <span /> <span /> <span />
        <div className="signin">
          <form id="loginForm" onSubmit={handleLogin} autoComplete="off">
            <div className="content">
              <h2>Sign In</h2>
              <div className="form">
                <div className="inputBox">
                  <input type="text" id="email" name="email" required="" />{" "}
                  <i>Email</i>
                </div>
                <div className="inputBox">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    required=""
                  />{" "}
                  <i>Password</i>
                </div>
                <div className="links">
                  {" "}
                  {/* <a href="#">Forgot Password</a> <a href="#">Signup</a> */}
                </div>

                <div className="inputBox">
                  {loading ? (
                    <LoaderSignal loader={true} marginOff={true} />
                  ) : (
                    <input type="submit" value="Login" />
                  )}
                </div>
                <div className="inputBox">
                  {resultDone && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {resultMsg}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
    // <>
    //   <div className={loading ? "login loading" : "login"}>
    //     <header className="header">
    //       <span className="text">LOGIN</span>
    //       <span className="loader"></span>
    //     </header>
    //     <form
    //       className="form"
    //       id="loginForm"
    //       onSubmit={handleLogin}
    //       autoComplete="off"
    //     >
    //       <input
    //         type="text"
    //         id="email"
    //         name="email"
    //         className="input"
    //         placeholder=" Email"
    //       />
    //       <input
    //         type="password"
    //         id="password"
    //         name="password"
    //         className="input"
    //         placeholder="Password"
    //       />
    //       <button class="btn__log" type="submit"></button>
    //     </form>
    //   </div>
    //   {resultDone ? <Popups text={resultMsg} left={50} /> : <div></div>}
    // </>

    // <div className="container-login">
    //   <div className="screen">
    //     <div className="screen__content">
    //       <form
    //         className="login"
    //         id="loginForm"
    //         onSubmit={handleLogin}
    //         autoComplete="off"
    //       >
    //         <div className="login__field">
    //           <i className="login__icon fas fa-user"></i>
    //           <input
    //             type="text"
    //             id="email"
    //             name="email"
    //             className="login__input"
    //             value="fathi.sulieman34@gmail.com"
    //             placeholder="User name / Email"
    //           />
    //         </div>
    //         <div className="login__field">
    //           <i className="login__icon fas fa-lock"></i>
    //           <input
    //             type="password"
    //             id="password"
    //             name="password"
    //             value="password"
    //             className="login__input"
    //             placeholder="Password"
    //           />
    //         </div>
    //         <button className="button login__submit" type="submit">
    //           <span className="button__text">Log In Now</span>
    //           <i className="button__icon fas fa-chevron-right"></i>
    //         </button>
    //       </form>
    //       <div className="social-login">
    //         {/* <h3>log in via</h3> */}
    //         {/* <div className="social-icons">
    //             <a href="#" className="social-login__icon fab fa-instagram"></a>
    //             <a href="#" className="social-login__icon fab fa-facebook"></a>
    //             <a href="#" className="social-login__icon fab fa-twitter"></a>
    //           </div> */}
    //       </div>
    //     </div>
    //     <div className="screen__background">
    //       <span className="screen__background__shape screen__background__shape4"></span>
    //       <span className="screen__background__shape screen__background__shape3"></span>
    //       <span className="screen__background__shape screen__background__shape2"></span>
    //       <span className="screen__background__shape screen__background__shape1"></span>
    //     </div>
    //   </div>
    // </div>
  );
}
